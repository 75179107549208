import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./assets/css/spinkit.css";
import router from "./router";
import store from "./store";
import "./axios";
import OneSignalVue from "onesignal-vue";

Vue.config.productionTip = false;

//use one signal
Vue.use(OneSignalVue);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: "1a78c173-14fd-42ca-a9cf-0359e9ddfdd8" });
  },
}).$mount("#app");
