<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  methods: {},
};
</script>
<style scoped lang="scss">
.home {
  background-image: url(../assets/images/members_bg.jpg) !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
