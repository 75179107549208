import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import EmailVerification from "../views/EmailVerification.vue";
import ResetPassword from "../views/ResetPassword.vue";
import Policy from "../views/Policy.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/verify",
    name: "Verify Email",
    component: EmailVerification,
  },
  {
    path: "/reset",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
