<template>
  <v-app>
    <div class="background"></div>
    <v-main class="d-flex justify-center align-center" v-if="verifySuccess">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-4">
          <div class="text-center">
            <v-avatar size="100" color="green lighten-5">
              <img
                class="login-img"
                src="../assets/images/verified.png"
                alt="IIFP Logo"
              />
            </v-avatar>
            <h2 class="login-title">Email Verification Successful</h2>
          </div>
        </v-card>
      </v-col>
    </v-main>

    <v-main class="d-flex justify-center align-center" v-if="verifyFailure">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-4">
          <div class="text-center">
            <v-avatar size="100" color="green lighten-5">
              <img
                class="login-img"
                src="../assets/images/fail.png"
                alt="IIFP Logo"
              />
            </v-avatar>
            <h2 class="login-title">Email Verification Failed</h2>
          </div>
        </v-card>
      </v-col>
    </v-main>

    <div class="loading" v-if="loading">
      <div class="sk-flow">
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
      </div>
    </div>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#106b42"
      v-model="successSnackbar"
    >
      <span class="toast-msg">{{ successMsg }}</span>
    </v-snackbar>

    <v-snackbar
      :timeout="2000"
      rounded="pill"
      top
      color="#C60018"
      v-model="failureSnackbar"
    >
      <span class="toast-msg">{{ errorMsg }}</span>
    </v-snackbar>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  name: "EmailVerification",

  data: () => ({
    token: "",
    verifySuccess: false,
    verifyFailure: false,
    loading: false,
    successSnackbar: false,
    successMsg: "",
    failureSnackbar: false,
    errorMsg: "",
  }),
  created() {
    //get token from link
    this.token = this.$route.query.token;

    //verify email
    this.verifyEmail();
  },
  methods: {
    verifyEmail() {
      //check if the form is valid
      if (this.token != null && this.token != "") {
        //start loading
        this.loading = true;

        //api call
        axios
          .post(
            "user/verify-email",
            {
              data: null,
            },
            {
              params: {
                token: this.token,
              },
            }
          )
          .then((response) => {
            console.log(response.data);

            //stop loading
            this.loading = false;

            //show failure screen
            this.verifySuccess = true;

            //show toast
            this.successSnackbar = true;
            this.successMsg = response.data.message;
          })
          .catch((error) => {
            console.log(error.response.data);

            //stop loading
            this.loading = false;

            //show failure screen
            this.verifyFailure = true;

            //show toast
            this.failureSnackbar = true;
            this.errorMsg = error.response.data.message;
          });
      } else {
        //show toast
        this.failureSnackbar = true;
        this.errorMsg = "No token was received!";
      }
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url(../assets/images/members_bg.jpg) !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.loading {
  align-content: center;
  align-items: center;
  align-self: center;
  margin: auto;
  padding: 100px;
  border-bottom: 1px solid #106b42;
}

.login-img {
  width: 60%;
  height: auto;
}

.login-title {
  color: #106b42;
  margin-top: 20px;
}

.login-btn {
  color: #106b42;
}

.toast-msg {
  color: #ffffff !important;
  font-weight: 600 !important;
}

.forgot-password {
  font-size: 16px;
  color: #106b42 !important;
  font-weight: bold;
  text-decoration: none;
}
</style>
