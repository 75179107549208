<template>
  <v-app>
    <div class="background"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="6" class="mx-auto">
        <v-card class="pa-4">
          <h1>PRIVACY POLICY</h1>
          <p>
            Data IIFP Mobile collect from you is used mainly for identification
            purpose and for providing you with quality and professional services
            rendered by the Institution. We will neither lend nor put for sale
            on any of your personal details nor use it for any other activity
            outside this purpose.
          </p>
          <p>
            <strong>Introduction</strong><br />
            This App is owned and operated by Institute of Islamic Finance
            Professionals, Nigeria also popularly known as IIFP Nigeria (“the
            Company”) with its website located at ‘www.iifpnigeria.org’ and
            'www.iifpmobile.com.ng', (“the Website”). For the purposes of this
            Privacy Policy, unless otherwise specified, all references to
            Institute of Islamic Finance Professionals Nigeria includes the
            Company and the Website.<br />
            The Company is the pioneer of Islamic Financial Institution in
            Nigeria. The Institute is established for building capacity,
            promoting Islamic financial practices and for advocating best
            practice in Islamic Financial sector in Nigeria and globally through
            research, teaching and learning activities for professional
            membership. The IIFP is dedicated to supporting professionals whose
            daily efforts impact people’s lives in ways that are felt far beyond
            the workplace; by helping to create better managers and leaders.<br />
          </p>
          <p>
            Your privacy on the Internet is of the utmost importance to us. We
            will never share your personal information with any third party, but
            because we may gather certain types of information about our users,
            we feel you should fully understand our policy and the terms and
            conditions surrounding the capture and use of that information.<br />
            This privacy statement discloses what information we gather and how
            we use it.<br />
            The private information you provide on the Website will be used only
            for its intended purpose.
          </p>
          <p>
            <strong>PERSONAL INFORMATION</strong><br />
            By using our website or "IIFP Mobile" our mobile application, you
            consent to the data practices described hereinafter
          </p>
          <p>
            As a general rule, the Company does not collect personal information
            about you when you visit the Website or download the mobile
            application, unless you choose to provide such information to us.
            Submitting personal information through the Website or Mobile
            application is voluntary. This excludes details required to run our
            applications. By doing so, you are giving the Company your
            permission to use the information for the stated purpose. However,
            not providing certain information may result in the Company’s
            inability to provide you with the Services (hereinafter defined in
            Clause<br />
            If you choose to provide us with personal information on the Website
            or Mobile applications, through methods such as submitting
            information through our Website, giving the Company a call and/or
            sending us an email at info@iifpnigeria.org, we may use that
            information to help us provide you with information and/or
            service(s) (collectively referred to as (“the Services”)).<br />
            We may also use the personal information to respond to any of your
            message(s) and/or feedback(s). The information we may receive from
            you varies based on what you do when visiting the Website.
          </p>
          <p>
            <strong>COLLECTION OF PERSONAL INFORMATION</strong><br />
            By using the Website, Mobile Applications and its functions, you may
            provide basic information such as, including but not limited to,
            your name, phone number, email address which allows us to send
            information, provide updates and/or process the type of Services you
            so desire.<br />
            If you sign up with the Company and/or purchase services through the
            Company, we may also collect, inter alia, billing and credit card
            information. All which are temporary and gets completely purged from
            our systems after usage.<br />
            The Company encourages you to review the privacy statements of the
            Websites or Mobile Application you choose to link to and from the
            Company so that you can understand how those Websites collect, use
            and share your information (“Third Party Website”). The Company is
            not responsible for the privacy statements or other contents on the
            Third Party Website outside of the Company’s Website. We are the
            first professionally repairs and maintenance services company
            rendering repairs and maintenance services at affordable rate and
            sustainable manner.
          </p>
          <p>
            <strong>USE OF YOUR PERSONAL INFORMATION</strong><br />
            We encourage you to review the privacy statements of the Websites
            you choose to link to and from the Company so that you can
            understand how those Websites collect, use and share your
            information (“Third Party Website”). The Company is not responsible
            for the privacy statements or other contents on the Third Party
            Website outside of the Company’s Website.<br />
            The Company encourages you to review the privacy statements of the
            Websites you choose to link to and from the Company so that you can
            understand how those Websites collect, use and share your
            information (“Third Party Website”). The Company is not responsible
            for the privacy statements or other contents on the Third Party
            Website outside of the Company’s Website.<br />
            USE OF YOUR PERSONAL INFORMATION<br />
            The Company collects and uses your personal information to operate
            the Website and deliver the Services. The Company does not sell,
            rent or lease its customer list to third parties.
          </p>
          <p>
            The Company may, from time to time, contact you on behalf of,
            including but not limited to, third parties about a particular
            offering that may be of interest to you. In those cases, your
            personal information (i.e. email, name, address, telephone number)
            is transferred to the third party.
          </p>
          <p>
            The Company may share data between/with, inter alia, the third
            parties to help perform statistical analysis send you email, provide
            customer support, or arrange for deliveries. All such third parties
            are prohibited from using your personal information except to
            provide these Services to the Company and they are required to
            maintain the confidentiality of your information.
          </p>
          <p>
            The Company may keep track of the Website and pages our users visit
            within the Company, in order to determine what the Company services
            are the most popular. The data is used to deliver customized content
            and advertising within the Company to customers whose behaviour
            indicates they are interested in a particular subject area.
          </p>
          <p>
            <strong>DISCLOSURE OF YOUR PERSONAL INFORMATION</strong><br />
            The personal information you provide to us whether voluntarily or
            automatically as the foregoing paragraphs describes, may be used and
            disclosed without limitations, to our employees, staffs, insurers,
            professionals, agents, suppliers, subcontractors and/or other
            parties we deem fit for the purpose and in the manner reasonably
            necessary for the carrying out of the Services and for purposes set
            out in this Privacy Policy.
          </p>
          <p>
            Personal information may be disclosed and/or supplied between the
            third parties. When personal information is disclosed and/or
            supplied between the third parties, all terms and conditions
            contained herein shall have effect for the purpose of protecting
            your personal information.
          </p>
          <p>
            Compliance with legal requirements for the enforcement of law,
            regulations, court orders, subpoena, warrant during the course of a
            legal proceedings or otherwise may render our need to disclose
            personal information.
          </p>
          <p>
            Personal information may also be used to protect and safeguard the
            copyright, trademarks, legal rights, intellectual property rights or
            safety of the Company.
          </p>
          <p>
            <strong>SECURITY OF YOUR PERSONAL INFORMATION</strong><br />
            The Company secures your personal information from unauthorized
            access, use or disclosure
          </p>
          <p>
            <strong>CHILDREN UNDER THIRTEEN</strong><br />
            The Company does not knowingly collect personal information from
            children under the age of thirteen. If you are under the age of
            thirteen, you must ask your parent or guardian for permission to use
            this Website.
          </p>
          <p>
            <strong>OPT-OUT &amp; UNSUBSCRIBE</strong><br />
            The Company respects your privacy and gives you an opportunity to
            opt-out of receiving information in respect of the Services by
            contacting our customers support via info@iifpnigeria.org
          </p>
          <p>
            <strong>CHANGES</strong><br />
            The terms contained in this Privacy Policy may change and subject to
            modification and amendments. All modifications and the time of the
            changes and modification will be subject to the sole direction of
            the Company and such changes will have immediate effect. No notice
            will be given to you prior to such changes and/or modifications; you
            are therefore advised to review our Privacy Policy from time to time
          </p>
          <p>
            <strong>CONTACT</strong><br />
            The Company welcomes your questions or comments regarding the
            foregoing terms.<br />
            Email: info@iifpnigeria.org<br />
            Phone: +2348171013311
          </p>
        </v-card>
      </v-col>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Policy",

  data: () => ({}),

  methods: {},
};
</script>
<style scoped>
.background {
  background-image: url(../assets/images/members_bg.jpg) !important;
  height: 100vh;
  width: 100%;
  display: block;
  position: fixed;
  top: 0;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.main-content {
  background-color: white;
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
</style>
